// Copyright 2022 Merit International Inc. All Rights Reserved.

import { Some } from "../utils/Some";
import { computed } from "zustand-middleware-computed-state";
import { createJSONStorage, persist } from "zustand/middleware";
import { useTokenStore } from "./tokenStore";
import { create as zustandCreate } from "zustand";
import AsyncStorage from "@react-native-async-storage/async-storage";
import type { LoginSuccessAsParentResponse } from "../__generated__/LoginRoute";
import type { PersistOptions } from "zustand/middleware";
import type { StateCreator } from "zustand";

type Parent = LoginSuccessAsParentResponse;

type User = Parent;

export type SelectedOrg = {
  readonly id: string;
  readonly templateId: string;
  readonly name: string;
};

type Store = {
  readonly user: User | undefined;
  readonly setUser: (user: User) => void;
  readonly logout: () => void;
};

type Computed = {
  readonly parent: Parent | undefined;
  readonly isParentSignedIn: boolean;
};

export type UserState = Computed & Store;

// HACK: workaround for https://github.com/pmndrs/zustand/issues/650 in recent zustand versions
type TypedPersist = (
  config: StateCreator<UserState>,
  options: PersistOptions<UserState>,
) => StateCreator<UserState>;
type TypedCompute = (
  create: StateCreator<Store>,
  compute: (state: Store) => Computed,
) => StateCreator<UserState>;

export const useUserStore = zustandCreate<UserState>(
  (persist as unknown as TypedPersist)(
    (computed as unknown as TypedCompute)(
      set => ({
        logout: () => {
          set({ user: undefined });

          useTokenStore.setState({
            token: undefined,
          });
        },
        setUser: (user: User) => {
          set({ user });
        },
        user: undefined,
      }),
      state => {
        function getParent() {
          const { user } = state;
          if (Some(user)) {
            return user;
          }

          return undefined;
        }

        function isParentSignedIn() {
          const { user } = state;

          return Some(user);
        }

        return {
          isParentSignedIn: isParentSignedIn(),
          parent: getParent(),
        };
      },
    ),
    {
      name: "userstate-storage",
      storage: createJSONStorage(() => AsyncStorage),
    },
  ),
);
