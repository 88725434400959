import { ActivityIndicator, Text } from "react-native";
import { CenteredContent } from "../Layouts/CenteredContent";
import { Some } from "../utils/Some";
import { useLogout } from "../hooks/auth";
import { useUserStore } from "../store/userStore";
import React, { useEffect } from "react";

type Props = {
  readonly text?: string;
};

export const LoadingWithTimeout = ({ text }: Props) => {
  const logoutFromAuth0 = useLogout();
  const logoutFromStore = useUserStore(_ => _.logout);

  useEffect(() => {
    const timeout = setTimeout(() => {
      logoutFromAuth0();
      logoutFromStore();
    }, 10000);

    return () => {
      clearTimeout(timeout);
    };
  }, [logoutFromAuth0, logoutFromStore]);

  return (
    <CenteredContent>
      <ActivityIndicator color="#3D7AA9" />
      {Some(text) && <Text style={{ marginTop: 16 }}>{text}</Text>}
    </CenteredContent>
  );
};
