import * as SplashScreen from "expo-splash-screen";
import { AgentClient } from "../services/AgentClient";
import { Loading } from "../components/Loading";
import { LoadingWithTimeout } from "../components/LoadingWithTimeout";
import { Platform } from "react-native";
import { Router } from "../Router";
import { Some } from "../utils/Some";
import { TermsOfServiceModal } from "../components/TermsOfServiceModal";
import { UpgradeAppScreen } from "../screens/UpgradeAppScreen";
import { jwtDecode } from "jwt-decode";
import { useApi } from "../services/useApi";
import { useDefaultErrorHandler } from "../utils/useDefaultErrorHandler";
import { useMeritAuth0 } from "../hooks/auth";
import { useUserStore } from "../store/userStore";
import { useUserTypeWithLoggedInDetails } from "../hooks/useRoutes";
import Constants from "expo-constants";
import React, { useEffect, useMemo, useState } from "react";
import type { MeritUserInfo } from "../types/user";

export function AppRoot() {
  const [hasFetchedUser, setHasFetchedUser] = useState(false);
  const [showUpgradeAppScreen, setShowUpgradeAppScreen] = useState(false);
  const [showToS, setShowToS] = useState(false);
  const { errorHandler } = useDefaultErrorHandler();
  const { userClient, versionClient } = useApi();
  const setUser = useUserStore(_ => _.setUser);
  const loggedInUser = useUserStore(_ => _.user);
  const { accessToken, isAuthenticated, isLoading: isAuth0Loading } = useMeritAuth0();
  const userTypeWithLoggedInDetails = useUserTypeWithLoggedInDetails();

  useEffect(() => {
    const isAppVersionAllowed = async () => {
      if (
        Some(Constants.expoConfig) &&
        Some(Constants.expoConfig.version) &&
        (Platform.OS === "android" || Platform.OS === "ios")
      ) {
        const response = await versionClient.getAppUpdateInfo(Constants.expoConfig.version);
        setShowUpgradeAppScreen(response.updateApp);
      }
    };
    isAppVersionAllowed();
  }, [versionClient]);

  useEffect(() => {
    const getUser = async () => {
      try {
        if (
          isAuthenticated &&
          Some(accessToken) &&
          accessToken !== "" &&
          Some(loggedInUser) &&
          Some(loggedInUser.type)
        ) {
          const { agentID } = jwtDecode<MeritUserInfo>(accessToken);
          const agentClient = AgentClient(accessToken);
          const { capabilitiesApproved, tosAccepted } = await agentClient.getLinks(agentID);
          if (capabilitiesApproved && tosAccepted) {
            if (Some(loggedInUser)) {
              // TODO: Clean up later
              const user = await userClient.getUserByParent();

              if (Some(user)) {
                setUser(user);
                setShowToS(false);
              } else {
                setHasFetchedUser(true);
              }
            }
          } else {
            setShowToS(true);
          }
        }
      } catch (error: unknown) {
        errorHandler(error);
      } finally {
        await SplashScreen.hideAsync();
      }
    };

    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, errorHandler, isAuthenticated, setUser, userClient, loggedInUser?.type]);

  const componentToRender: string | undefined = useMemo(() => {
    if (isAuth0Loading) {
      return "loading";
    }
    if (hasFetchedUser) {
      return undefined;
    }
    if (isAuthenticated && userTypeWithLoggedInDetails === "preLogin") {
      if (showToS) {
        return "termsOfService";
      }

      return "loading";
    }

    return undefined;
  }, [isAuth0Loading, isAuthenticated, userTypeWithLoggedInDetails, hasFetchedUser, showToS]);

  if (
    userTypeWithLoggedInDetails === "preLogin" &&
    showUpgradeAppScreen &&
    (Platform.OS === "android" || Platform.OS === "ios")
  ) {
    return <UpgradeAppScreen />;
  }

  if (componentToRender === "loading") {
    // HACK: On iOS, inspite of uninstalling the application still we have access to the token
    // Also isAuthenticated returns true because of which a continuous loader is shown
    // We intend to clean up AppRoot and auth related files as part of MP-362
    // For time being, we will forcefully logout if there is a loader which renders for more than 10 seconds
    // Anyways it doesn't make a good experience to show a continuous loader for more than 10 seconds
    return Platform.OS === "web" ? <Loading /> : <LoadingWithTimeout />;
  }

  if (componentToRender === "termsOfService") {
    return <TermsOfServiceModal />;
  }

  return <Router />;
}
