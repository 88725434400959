// Copyright 2022 Merit International Inc. All Rights Reserved

import { Image, Pressable, StyleSheet, View } from "react-native";
import { Some } from "../../utils/Some";
import { getPsuedoState } from "../../utils/getPseudoState";
import { setTestProps } from "../../utils/propHelper";
import { useActive, useFocus, useHover } from "react-native-web-hooks";
import React, { useMemo, useRef } from "react";
import type { ButtonProps } from "./types";

const styles = StyleSheet.create({
  base: {
    alignItems: "center",
    borderRadius: 4,
    borderWidth: 1,
    display: "flex",
    justifyContent: "space-around",
  },
  innerWrapper: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    paddingHorizontal: 24,
  },
});

export function PrimaryButton({
  accessibilityHint,
  accessibilityLabel,
  customContent,
  disabled = false,
  height,
  iconLeft,
  onPress,
  testID,
  textComponent,
}: ButtonProps) {
  const ref = useRef(null);
  const isHovered = useHover(ref);
  const isFocused = useFocus(ref);
  const isActive = useActive(ref);
  const buttonState = useMemo(
    () => getPsuedoState({ disabled, isActive, isFocused, isHovered }),
    [isHovered, isFocused, isActive, disabled],
  );

  // Memoized styles so that we only calculate once, not on every rerender
  const calculatedStyles = useMemo(() => {
    switch (buttonState) {
      case "disabled": {
        return {
          button: {
            backgroundColor: "#FAFBFC",
            borderColor: "#C1C7D0",
          },
        };
      }

      case "active": {
        return {
          button: {
            backgroundColor: "rgba(75,121,156,1)",
            borderColor: "#3D7AA9",
          },
        };
      }

      case "hovered": {
        return {
          button: {
            backgroundColor: "rgba(86,138,178,1)",
            borderColor: "#3D7AA9",
          },
        };
      }

      case "focused": {
        return {
          button: {
            backgroundColor: "rgba(250,251,252,1)",
            borderColor: "rgba(61,122,169,1)",
          },
        };
      }

      case "none": {
        return {
          button: {
            backgroundColor: "#6CADDF",
            borderColor: "#3D7AA9",
          },
        };
      }

      default:
        throw new Error("Invalid Button State");
    }
  }, [buttonState]);

  return (
    <Pressable
      accessibilityHint={accessibilityHint}
      accessibilityLabel={accessibilityLabel}
      disabled={disabled}
      onPress={onPress}
      ref={ref}
      role="button"
      {...setTestProps({ name: testID })}
    >
      <View
        style={[
          styles.base,
          calculatedStyles.button,
          {
            height,
          },
        ]}
      >
        {Some(customContent) ? (
          customContent
        ) : (
          <View style={styles.innerWrapper}>
            {Some(iconLeft) && (
              <View style={{ paddingRight: 8 }}>
                <Image accessible source={iconLeft} style={{ height: 16, width: 16 }} />
              </View>
            )}
            {textComponent}
          </View>
        )}
      </View>
    </Pressable>
  );
}
