import { AgentClient } from "../services/AgentClient";
import { LoginClient } from "../services/LoginClient";
import { jwtDecode } from "jwt-decode";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useToast } from "react-native-toast-notifications";
import { useUserStore } from "../store/userStore";
import type { MeritUserInfo } from "../types/user";
import type { UseMeritAuth0 } from "../types/auth";

const useLogin = (userType: string) => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { getAccessTokenSilently, loginWithPopup, logout } = useAuth0();
  const setUser = useUserStore(_ => _.setUser);
  const toast = useToast();

  const showToast = (message: string) => {
    toast.show(message, {
      duration: 1500,
      onClose: () => {
        logout({ logoutParams: { returnTo: window.location.origin } });
      },
      placement: "top",
      type: "danger",
    });
  };

  const doPkceFlow = async () => {
    try {
      await loginWithPopup({
        authorizationParams: { prompt: "login", scope: "openid profile offline_access" },
      });

      const accessToken = await getAccessTokenSilently();

      const { agentID } = jwtDecode<MeritUserInfo>(accessToken);

      const agentClient = AgentClient(accessToken);
      const { capabilitiesApproved, tosAccepted } = await agentClient.getLinks(agentID);

      if (!capabilitiesApproved || !tosAccepted) {
        return;
      }

      // TODO related to M4 migration: Make the useApi -> loginClient work - it should fetch the latest accessToken
      const loginClient = LoginClient(accessToken);
      if (userType === "Parent") {
        const response = await loginClient.loginSuccessAsParent();
        if (response.success) {
          setUser(response.data);
        } else {
          showToast(response.message);
        }
      } else {
        showToast("Invalid user type");
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(`Failed to login: ${String(err)}`);
      showToast("There was a problem logging in");
    }
  };

  return doPkceFlow;
};

const useLogout = () => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { logout: logoutAuth0 } = useAuth0();

  const logout = () => {
    logoutAuth0({ logoutParams: { returnTo: window.location.origin } });
  };

  return logout;
};

const useMeritAuth0 = (): UseMeritAuth0 => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { getAccessTokenSilently, isAuthenticated, isLoading, user } = useAuth0<MeritUserInfo>();

  const [accessToken, setAccessToken] = useState<string>();

  useEffect(() => {
    // eslint-disable-next-line functional/no-let
    let hasCleanedUp = false;
    const getAccessToken = async () => {
      const token = await getAccessTokenSilently();
      if (!hasCleanedUp) {
        setAccessToken(token);
      }
    };

    // Only try to get the accessToken if we are authenticated to avoid console errors
    if (isAuthenticated) {
      getAccessToken();
    }

    return () => {
      hasCleanedUp = true;
    };
  }, [getAccessTokenSilently, isAuthenticated]);

  return {
    accessToken: accessToken ?? "",
    isAuthenticated,
    isLoading,
    // Looks weird, but convert undefined to null for matching API
    user: user === undefined ? null : user,
  };
};

export { useLogin, useLogout, useMeritAuth0 };
