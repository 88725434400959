import { Login } from "../__generated__/Login";
import { Platform } from "react-native";
import { Some } from "../utils/Some";
import { User } from "../__generated__/User";
import { Version } from "../__generated__/Version";
import { config } from "../config/config";
import { useAuth0 as useAuth0Native } from "react-native-auth0";
import { useAuth0 as useAuth0Web } from "@auth0/auth0-react";
import { useMemo } from "react";
import { useMeritAuth0 } from "../hooks/auth";
import { useUserStore } from "../store/userStore";
import axios from "axios";
import type { ApiConfig } from "../__generated__/http-client";
export function useApi() {
  const { accessToken } = useMeritAuth0();
  const { logout } = useAuth0Web();
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { clearCredentials } = useAuth0Native();

  return useMemo(() => {
    const httpClientConfig: ApiConfig = {
      baseURL: config.api.baseUrl,
      transformRequest: [
        // @ts-expect-error this is always an []
        ...axios.defaults.transformRequest,
        (data: unknown, headers) => {
          if (Some(accessToken) && Some(headers)) {
            // eslint-disable-next-line functional/immutable-data, no-param-reassign
            headers.Authorization = `Bearer ${accessToken}`;
          }

          return data;
        },
      ],
    };
    const unauthorizedResponseInterceptor = (error: unknown) => {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        if (Platform.OS === "web") {
          logout({ logoutParams: { returnTo: window.location.origin } });
        } else {
          clearCredentials();
        }
        useUserStore.getState().logout();

        return;
      }
      throw error;
    };
    // A shame I have to do this for each instance
    const loginClient = new Login(httpClientConfig);
    const userClient = new User(httpClientConfig);
    const versionClient = new Version(httpClientConfig);
    versionClient.instance.interceptors.response.use(undefined, unauthorizedResponseInterceptor);
    loginClient.instance.interceptors.response.use(undefined, unauthorizedResponseInterceptor);
    userClient.instance.interceptors.response.use(undefined, unauthorizedResponseInterceptor);

    return {
      loginClient,
      userClient,
      versionClient,
    };
  }, [accessToken, logout, clearCredentials]);
}
