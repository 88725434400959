import { App } from "./App";
import { AuthProvider } from "./src/components/AuthProvider";
import { registerRootComponent } from "expo";

location.href = "https://www.parentalchoice.ok.gov";

const AppWithAuthProvider = () => (
  <AuthProvider>
    <App />
  </AuthProvider>
);

registerRootComponent(AppWithAuthProvider);
