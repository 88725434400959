// Copyright 2023 Merit International Inc. All Rights Reserved

import { AgentClient } from "../services/AgentClient";
import { Button } from "../components/Button";
import { ExternalLink } from "./ExternalLink";
import {
  Image,
  Modal,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { Loading } from "./Loading";
import { LoginClient } from "../services/LoginClient";
import { Some } from "../utils/Some";
import { config } from "../config/config";
import { jwtDecode } from "jwt-decode";
import { setTestProps } from "../utils/propHelper";
import { useAuth0 as useAuth0Native } from "react-native-auth0";
import { useAuth0 as useAuth0Web } from "@auth0/auth0-react";
import { useDefaultErrorHandler } from "../utils/useDefaultErrorHandler";
import { useDeviceSize } from "../utils/useDeviceSize";
import { useLogout, useMeritAuth0 } from "../hooks/auth";
import { useToast } from "react-native-toast-notifications";
import { useUserStore } from "../store/userStore";
import BackArrow from "../../assets/icons/back-arrow-gray.png";
import Checkbox from "expo-checkbox";
import MeritLogo from "../../assets/images/merit-platform-logo.png";
import React, { useState } from "react";
import ShowMoreInfoTooltipIcon from "../../assets/icons/information_outlined_m_default.png";
import UserMeritRelationLogo from "../../assets/images/user-merit-relation.png";
import type { MeritUserInfo } from "../types/user";

export const TermsOfServiceModal = () => {
  const { isDesktopOrLarger } = useDeviceSize();

  const logoutFromAuth0 = useLogout();
  const { getAccessTokenSilently } = useAuth0Web();
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { getCredentials } = useAuth0Native();
  const setUser = useUserStore(_ => _.setUser);
  const logoutFromStore = useUserStore(_ => _.logout);
  const toast = useToast();
  const { errorHandler } = useDefaultErrorHandler();
  const [isVisible, setIsVisible] = useState(true);
  const [showMoreInfoTooltip, setShowMoreInfoTooltip] = useState(false);

  const { user } = useMeritAuth0();
  const [isToSChecked, setToSChecked] = useState(false);

  const styles = StyleSheet.create({
    backButton: {
      alignItems: "flex-start",
    },
    container: {
      backgroundColor: "#FFFFFF",
      borderRadius: 4,
      paddingHorizontal: 32,
      paddingVertical: 32,
      width: isDesktopOrLarger ? "30%" : "90%",
    },
    footer: {
      flexDirection: "row",
      justifyContent: "flex-end",
      paddingBottom: 30,
      width: "100%",
    },
    footerLinks: {
      textDecorationLine: "underline",
    },
    header: {
      flexDirection: "row",
      justifyContent: "space-between",
      paddingVertical: 12,
      width: "100%",
    },
    headerContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 44,
    },
    headerText: {
      fontSize: 24,
      fontWeight: "700",
    },
    listAsterisk: {
      fontSize: 16,
      fontWeight: "500",
    },
    tooltip: {
      backgroundColor: "#007A98",
      borderRadius: 4,
      ...(Platform.OS === "web" && { boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)" }),
      paddingHorizontal: 16,
      paddingVertical: 12,
      position: "absolute",
      shadowColor: "rgba(0, 0, 0, 0.1)",
      shadowOpacity: 0.8,
      shadowRadius: 4,
      width: 200,
      zIndex: 2,
    },
    viewMeritListItem: {
      borderBottomWidth: 1,
      borderColor: "#E2E6E8",
      borderTopWidth: 1,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingVertical: 20,
    },
    viewNameEmailListItem: {
      borderColor: "#E2E6E8",
      borderTopWidth: 1,
      justifyContent: "center",
      paddingVertical: 20,
    },
    wrapper: {
      alignItems: "center",
      backgroundColor: "rgba(0,0,0, 0.5)",
      flex: 1,
      justifyContent: "center",
    },
  });

  const onCancel = () => {
    logoutFromStore();
    logoutFromAuth0();
  };

  const showToast = (message: string) => {
    toast.show(
      <Text {...setTestProps({ name: "errorMessage-TermsOfServiceModal" })}>{message}</Text>,
      {
        duration: 1500,
        onClose: () => {
          logoutFromStore();
          logoutFromAuth0();
        },
        placement: "top",
        type: "danger",
      },
    );
  };

  const acceptToSAndLinkAgent = async (accessToken: string) => {
    const agentClient = AgentClient(accessToken);
    const { agentID, entityID } = jwtDecode<MeritUserInfo>(accessToken);

    try {
      await agentClient.acceptToS(agentID);
      await agentClient.linkEntity(agentID, entityID);
    } catch (error) {
      errorHandler(error);
    }
  };

  // eslint-disable-next-line consistent-return
  const getAccessToken = async () => {
    if (Platform.OS === "web") {
      const token = await getAccessTokenSilently();

      return token;
    }
    const credentials = await getCredentials();
    if (Some(credentials)) {
      const token = credentials.accessToken;

      return token;
    }

    return "";
  };

  const onConfirm = async () => {
    setIsVisible(false);
    try {
      const accessToken = await getAccessToken();

      if (Some(accessToken)) {
        await acceptToSAndLinkAgent(accessToken);

        const loginClient = LoginClient(accessToken);

        const response = await loginClient.loginSuccessAsParent();
        if (response.success) {
          setUser(response.data);
        } else {
          showToast(response.message);
        }
      } else {
        showToast("Could not get a valid token");
      }
    } catch (error: unknown) {
      errorHandler(error);
    }
  };

  return (
    <View style={{ alignContent: "center", alignItems: "center", flex: 1 }}>
      <Loading />
      <Modal animationType="fade" onRequestClose={onCancel} transparent visible={isVisible}>
        <View style={styles.wrapper}>
          <View style={styles.container}>
            <View style={styles.headerContainer}>
              <Pressable onPress={onCancel}>
                <Image
                  accessible
                  aria-label="Back arrow"
                  source={BackArrow}
                  style={{ height: 24, width: 24 }}
                />
              </Pressable>
              <Image
                accessible
                aria-label="MeritLogo"
                resizeMode="contain"
                source={MeritLogo}
                style={{ height: 40, width: 76 }}
              />
              <View style={{ width: 24 }} />
            </View>
            <View style={{ alignItems: "center", paddingVertical: isDesktopOrLarger ? 40 : 0 }}>
              <Image
                accessible
                aria-label="UserMeritRelationLogo"
                resizeMode="contain"
                source={UserMeritRelationLogo}
                style={{ height: 76, width: 230 }}
              />
            </View>
            <View style={styles.header}>
              <Text style={styles.headerText}>{config.name}</Text>
            </View>
            <View>
              <Text style={{ lineHeight: 22, paddingBottom: 10, paddingTop: 5 }}>
                is requesting access to your Merit account
                {Some(user) && Some(user.email) && (
                  <>
                    <Text> on behalf of</Text>{" "}
                    <Text style={{ fontWeight: "600" }}>{user.email}</Text>
                  </>
                )}
              </Text>
            </View>
            <View style={{ paddingVertical: 10 }}>
              <View style={styles.viewNameEmailListItem}>
                <Text
                  style={styles.listAsterisk}
                  {...setTestProps({ name: "canViewNameAndEmail-TermsOfServiceModal" })}
                >
                  <Text style={{ fontSize: 20, marginRight: 10, textAlignVertical: "center" }}>
                    *
                  </Text>
                  View your name and primary email
                </Text>
              </View>
              <View style={styles.viewMeritListItem}>
                <Text
                  style={styles.listAsterisk}
                  {...setTestProps({ name: "canViewMerits-TermsOfServiceModal" })}
                >
                  <Text style={{ fontSize: 20, marginRight: 10, textAlignVertical: "center" }}>
                    *
                  </Text>
                  View all your merits
                </Text>
                {showMoreInfoTooltip && (
                  <View style={[styles.tooltip, { right: isDesktopOrLarger ? 0 : 10, top: 40 }]}>
                    <Text style={{ color: "#FFFFFF" }}>
                      This includes all accepted and pending merits
                    </Text>
                  </View>
                )}
                <TouchableOpacity
                  onPress={() => {
                    setShowMoreInfoTooltip(prevState => !prevState);
                  }}
                >
                  <Image
                    accessible
                    source={ShowMoreInfoTooltipIcon}
                    style={{ height: 20, marginRight: 20, width: 20 }}
                  />
                </TouchableOpacity>
              </View>
            </View>
            <View style={{ flexDirection: "row", paddingVertical: 25, width: "100%" }}>
              <Checkbox
                onValueChange={() => {
                  setToSChecked(prevState => !prevState);
                }}
                value={isToSChecked}
                {...setTestProps({ name: "tosCheckbox-TermsOfServiceModal" })}
              />
              <View style={{ flexDirection: "row", paddingHorizontal: 5 }}>
                {/* TODO: TBD */}
                <Text>Accept </Text>
                <ExternalLink
                  text="Terms of Service"
                  textStyle={{ textDecorationLine: "underline" }}
                  url="https://app.merits.com/terms-of-service"
                />
              </View>
            </View>
            <View style={styles.footer}>
              <Button
                onPress={onCancel}
                testID="cancelButton-TermsOfServiceModal"
                text="Cancel"
                type="secondary"
              />
              <View style={{ width: 16 }} />
              <Button
                disabled={!isToSChecked}
                onPress={() => {
                  onConfirm();
                }}
                testID="allowButton-TermsOfServiceModal"
                text="Allow"
              />
            </View>
            <>
              <Text style={{ lineHeight: 20 }}>
                To learn how Oklahoma ESA will handle your data, please review{" "}
                <ExternalLink
                  text="terms of service"
                  textStyle={styles.footerLinks}
                  url="https://app.merits.com/terms-of-service"
                />{" "}
                and{" "}
                <ExternalLink
                  text="privacy policy."
                  textStyle={styles.footerLinks}
                  url="https://app.merits.com/privacy-policy"
                />
              </Text>
            </>
          </View>
        </View>
      </Modal>
    </View>
  );
};
