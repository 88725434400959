// Copyright 2023 Merit International Inc. All Rights Reserved

import { NotFound } from "../../screens/ErrorScreens/NotFound";
import { SplashScreen as ParentLoginScreen } from "../../screens/SplashScreen";
import { config } from "../../config/config";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React from "react";
import type { PreLoginRouteParams } from "..";

const PreLoginRootStack = createNativeStackNavigator<PreLoginRouteParams>();

export const PreLoginRootStackNavigator = () => (
  <PreLoginRootStack.Navigator screenOptions={{ headerShown: false, title: config.name }}>
    <PreLoginRootStack.Screen component={ParentLoginScreen} name="Splash" />
    <PreLoginRootStack.Screen component={NotFound} name="NotFound" />
  </PreLoginRootStack.Navigator>
);
