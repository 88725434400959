// Copyright 2023 Merit International Inc. All Rights Reserved

import { Dashboard } from "../../screens/Dashboard";
import { NotEligibleScreen } from "../../screens/NotEligibleScreen";
import { NotFound } from "../../screens/ErrorScreens/NotFound";
import { Some } from "../../utils/Some";
import { config } from "../../config/config";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useUserStore } from "../../store/userStore";
import React from "react";
import type { ParentRouteParams } from "..";

const ParentWebRootStack = createNativeStackNavigator<ParentRouteParams>();

export const ParentWebRootStackNavigator = () => {
  const parent = useUserStore(_ => _.parent);
  const isParentEligible = Some(parent) && parent.children.length > 0;
  if (!isParentEligible) {
    return (
      <ParentWebRootStack.Navigator screenOptions={{ headerShown: false, title: config.name }}>
        <ParentWebRootStack.Screen component={NotEligibleScreen} name="NotEligible" />
      </ParentWebRootStack.Navigator>
    );
  }

  return (
    <ParentWebRootStack.Navigator screenOptions={{ headerShown: false, title: config.name }}>
      <ParentWebRootStack.Screen component={Dashboard} name="Dashboard" />
      <ParentWebRootStack.Screen component={NotFound} name="NotFound" />
    </ParentWebRootStack.Navigator>
  );
};
