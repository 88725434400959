// Copyright 2023 Merit International Inc. All Rights Reserved

import { Platform } from "react-native";
import { UnreachableCaseError } from "../utils/UnreachableCaseError";
import { useUserStore } from "../store/userStore";
import type { WebRouteParams } from "../navigation/Routes";

export type UserTypeWithLoggedInDetails = "parentLoggedInNative" | "parentLoggedInWeb" | "preLogin";

export const pathConfigMap: Record<keyof WebRouteParams, string> = {
  Dashboard: "/dashboard",
  NotEligible: "/not-eligible",
  NotFound: "*",
  Splash: "/",
};

const { Dashboard, NotEligible, NotFound, Splash } = pathConfigMap;

const parentWebRouteLinking = {
  config: {
    screens: {
      Dashboard,
      NotEligible,
      NotFound,
    },
  },
  prefixes: [""],
};

const parentNativeRouteLinking = {
  config: {
    screens: {
      Dashboard,
      NotEligible,
      NotFound,
    },
  },
  prefixes: [""],
};

const preLoginRouteLinking = {
  config: {
    screens: {
      NotFound,
      Splash,
    },
  },
  prefixes: [""],
};

export const useUserTypeWithLoggedInDetails = (): UserTypeWithLoggedInDetails => {
  const isParentSignedIn = useUserStore(_ => _.isParentSignedIn);

  const isParentSignedInWeb = isParentSignedIn && Platform.OS === "web";
  const isParentSignedInNative =
    isParentSignedIn && (Platform.OS === "android" || Platform.OS === "ios");

  if (isParentSignedInWeb) {
    return "parentLoggedInWeb";
  } else if (isParentSignedInNative) {
    return "parentLoggedInNative";
  }

  return "preLogin";
};

export const useRoutesLinking = () => {
  const userTypeWithLoggedInDetails = useUserTypeWithLoggedInDetails();

  switch (userTypeWithLoggedInDetails) {
    case "parentLoggedInNative":
      return parentNativeRouteLinking;
    case "parentLoggedInWeb":
      return parentWebRouteLinking;
    case "preLogin":
      return preLoginRouteLinking;
    default:
      throw new UnreachableCaseError(userTypeWithLoggedInDetails);
  }
};
