import "./src/config/Datadog";
import "react-native-url-polyfill/auto";
import * as Sentry from "sentry-expo";
import * as SplashScreen from "expo-splash-screen";
import { AppRoot } from "./src/AppRoot/AppRoot";
import { SentryErrorBoundary } from "./src/utils/SentryErrorBoundary";
import { ToastMessage } from "./src/utils/ToastMessage";
import { config } from "./src/config/config";
import { useAuthStateStore } from "./src/store/authStateStore";
import { useFonts } from "expo-font";
import { useUserStore } from "./src/store/userStore";
import React, { useEffect, useState } from "react";

Sentry.init({
  ...config.sentry,
  debug: config.sentry.environment === "development",
  enableInExpoDevelopment: true,
  enableWatchdogTerminationTracking: false,
  enabled: config.sentry.environment !== "development",
});

export const App = () => {
  const [storesReady, setStoresReady] = useState(false);

  const [fontsLoaded] = useFonts({
    /* eslint-disable */
    ProximaNova: require("./assets/fonts/ProximaNova.otf"),
    ProximaNovaBold: require("./assets/fonts/ProximaNovaABold.otf"),
    ProximaNovaMedium: require("./assets/fonts/ProximaNovaAMedium.otf"),
    /* eslint-enable */
  });

  useEffect(() => {
    const hydrate = async () => {
      // Native: Keep the splash screen visible while we fetch resources
      await SplashScreen.preventAutoHideAsync();
      // @ts-expect-error some middleware typing issue, these methods work
      await Promise.all([useUserStore.persist.rehydrate(), useAuthStateStore.persist.rehydrate()]);
      setStoresReady(true);
    };

    hydrate();
  }, []);

  if (!storesReady || !fontsLoaded) {
    return null;
  }

  return (
    <SentryErrorBoundary>
      <ToastMessage>
        <AppRoot />
      </ToastMessage>
    </SentryErrorBoundary>
  );
};
