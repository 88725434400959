// Copyright 2022 Merit International Inc. All Rights Reserved

import { ExternalLink } from "../components/ExternalLink";
import { Footer } from "../components/Footer";
import { Image, Pressable, SafeAreaView, StyleSheet, Text, View } from "react-native";
import { setTestProps } from "../utils/propHelper";
import { useDeviceSize } from "../utils/useDeviceSize";
import { useLogin } from "../hooks/auth";
import Logo from "../../assets/images/logo.png";
import MeritSmile from "../../assets/images/merit-smile-transparent.png";
import React from "react";
import SplashImg from "../../assets/images/splash.png";

export const SplashScreen = () => {
  const promptLogin = useLogin("Parent");
  const { isDesktopOrLarger } = useDeviceSize();

  const styles = StyleSheet.create({
    alignItems: {
      alignItems: isDesktopOrLarger ? "flex-start" : "center",
    },
    button: {
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "center",
      paddingHorizontal: 24,
      paddingVertical: isDesktopOrLarger ? 6 : 12,
    },
    buttonWrapper: {
      backgroundColor: "#00CCC0",
      borderRadius: 4,
      paddingVertical: 8,
      width: 270,
    },
    container: {
      backgroundColor: "#ffffff",
      flex: 1,
    },
    heading: {
      fontSize: isDesktopOrLarger ? 42 : 28,
      fontWeight: "500",
    },
    logo: {
      ...(isDesktopOrLarger
        ? {
            height: 64,
            width: 64,
          }
        : {
            height: 140,
            width: 140,
          }),
    },
    section: {
      alignItems: "center",
      justifyContent: "center",
    },
    textAlign: {
      textAlign: isDesktopOrLarger ? "left" : "center",
    },
  });

  return (
    <SafeAreaView style={{ backgroundColor: "#ffffff", flex: 1 }}>
      <View style={styles.container}>
        <View style={[{ flex: 1 }, isDesktopOrLarger ? { flexDirection: "row" } : styles.section]}>
          <View style={[styles.alignItems, { flex: 1, paddingTop: 72 }]}>
            <View
              style={{ flex: 1, paddingLeft: isDesktopOrLarger ? 84 : undefined, width: "100%" }}
            >
              <View
                style={[
                  styles.alignItems,
                  { flex: isDesktopOrLarger ? undefined : 5, justifyContent: "center" },
                ]}
              >
                <Image
                  accessible
                  aria-label="ACE logo"
                  resizeMode="contain"
                  source={Logo}
                  style={styles.logo}
                />
              </View>
              <View style={{ flex: isDesktopOrLarger ? undefined : 5 }}>
                <View style={{ height: 28 }} />
                <View style={{ paddingVertical: 16 }}>
                  <Text
                    style={[styles.textAlign, styles.heading]}
                    {...setTestProps({ name: "welcomeTitle-SplashScreen" })}
                  >
                    Welcome to Oklahoma ESA
                  </Text>
                </View>
                <View>
                  <Text
                    style={[styles.textAlign, { color: "#0B1D37", fontSize: 16 }]}
                    {...setTestProps({ name: "loginToManageText-SplashScreen" })}
                  >
                    Login to manage your account
                  </Text>
                </View>
                <View style={{ height: 60 }} />
                <View style={[styles.alignItems, { paddingVertical: 16 }]}>
                  <Pressable
                    onPress={() => {
                      promptLogin();
                    }}
                    role="button"
                    style={styles.buttonWrapper}
                    {...setTestProps({ name: "loginWithMeritButton-SplashScreen" })}
                  >
                    <View style={styles.button}>
                      <Image
                        aria-label="Merit smile logo"
                        resizeMode="contain"
                        source={MeritSmile}
                        style={{ height: 16, width: 44 }}
                      />
                      <View style={{ paddingHorizontal: 16 }}>
                        <Text>|</Text>
                      </View>
                      <Text style={[styles.textAlign, { fontSize: 16, fontWeight: "500" }]}>
                        Login With Merit
                      </Text>
                    </View>
                  </Pressable>
                </View>

                <ExternalLink
                  testID="helpLink-SplashScreen"
                  text="Need help accessing your Merit account?"
                  url="https://help.parentalchoice.ok.gov/article/331-how-do-i-access-my-merit-account"
                />
              </View>
            </View>
            {isDesktopOrLarger && (
              <View style={{ paddingLeft: 84 }}>
                <Footer />
              </View>
            )}
          </View>
          {isDesktopOrLarger && (
            <View style={{ backgroundColor: "#3D7AA9", flex: 1, justifyContent: "center" }}>
              <Image
                accessible
                aria-label="ACE dashboard image"
                source={SplashImg}
                style={{ height: 500, width: "100%" }}
              />
            </View>
          )}
        </View>
      </View>
    </SafeAreaView>
  );
};
