// Copyright 2022 Merit International Inc. All Rights Reserved

import { Platform, Pressable, StyleSheet, Text, View } from "react-native";
import { Tooltip } from "./Tooltip/Tooltip";
import { UnreachableCaseError } from "../utils/UnreachableCaseError";
import { formatCurrency } from "../utils/FormatHelper";
import { setTestProps } from "../utils/propHelper";
import { useDeviceSize } from "../utils/useDeviceSize";
import React, { useState } from "react";
import dayjs from "dayjs";
import type { LoginSuccessAsParentResponse } from "../__generated__/LoginRoute";

type Children = LoginSuccessAsParentResponse["children"];
type Title = "Credit Amount Disbursed" | "Credit Amount Remaining" | "Total Credit Amount";
type Status = Children[number]["claims"][number]["status"];

type Props = {
  readonly amount: number;
  readonly childName: string;
  readonly title: Title;
};

const AmountCard = ({ amount, childName, title }: Props) => {
  const styles = StyleSheet.create({
    amountIcon: {
      height: 20,
      width: 20,
    },
    amountText: {
      fontSize: 28,
      fontWeight: "600",
    },
    bodyText: {
      fontSize: 14,
      paddingBottom: 8,
    },
    contentWrapper: {
      backgroundColor: "#FAFBFC",
      borderRadius: 5,
      marginHorizontal: 8,
      marginVertical: 8,
      paddingHorizontal: 16,
      paddingVertical: 10,
    },
  });

  const getTooltipText = () => {
    switch (title) {
      case "Credit Amount Disbursed":
        return "This is the amount sent to the school.";
      case "Credit Amount Remaining":
        return "This is the amount remaining. Note that all credits are split into two payments.";
      case "Total Credit Amount":
        return "This is the total amount you qualify for, less any taxes owed. Note that it may not be immediately updated.";
      default:
        throw new UnreachableCaseError(title);
    }
  };

  return (
    <View style={{ flex: 1 }}>
      <View style={styles.contentWrapper}>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Text style={styles.bodyText}>{title}</Text>
          <View style={{ alignItems: "flex-end" }}>
            <Tooltip text={getTooltipText()} />
          </View>
        </View>
        <Text
          style={styles.amountText}
          {...setTestProps({ name: `${childName}-${title}Amount-ChildCard` })}
        >
          {formatCurrency(amount)}
        </Text>
      </View>
    </View>
  );
};

// eslint-disable-next-line react/no-multi-comp
export const ChildCard = ({ claims, dollars, firstName, lastName }: Children[number]) => {
  const { isDesktopOrLarger } = useDeviceSize();
  const NUMBER_OF_MINIMUM_CLAIMS_TO_DISPLAY = 2;
  const [showAllClaims, setShowAllClaims] = useState(false);
  const claimsToShow = showAllClaims
    ? claims
    : claims.slice(0, NUMBER_OF_MINIMUM_CLAIMS_TO_DISPLAY);

  const styles = StyleSheet.create({
    boxWrapper: {
      marginHorizontal: 8,
      marginVertical: 8,
    },
    buttonWrapper: {
      alignItems: "center",
      flexDirection: "row",
      marginHorizontal: isDesktopOrLarger ? 8 : 0,
      marginVertical: 8,
      paddingVertical: 10,
    },
    columnHeading: {
      fontSize: 14,
      fontWeight: "600",
    },
    columnHeadingWrapper: {
      flex: 1,
      paddingLeft: 8,
      paddingTop: 10,
    },
    container: {
      borderColor: "#C1C7D0",
      borderRadius: 4,
      borderWidth: 1,
    },
    contentWrapper: {
      backgroundColor: "#EEEBE3",
      borderRadius: 5,
      marginHorizontal: 8,
      marginVertical: 8,
      paddingHorizontal: 16,
      paddingVertical: 10,
    },
    header: {
      alignItems: "center",
      backgroundColor: "#FAFBFC",
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      flexDirection: "row",
      justifyContent: "space-between",
      padding: 16,
    },
    headerText: {
      color: "#000000",
      flexShrink: 1,
      fontSize: 16,
    },
    horizontalLine: {
      borderBottomColor: "#C1C7D0",
      borderBottomWidth: 1,
      marginVertical: 10,
    },
    tableHeadingWrapper: {
      flexDirection: isDesktopOrLarger ? "row" : "column",
      marginTop: 5,
    },
  });
  const claimStyles = StyleSheet.create({
    borderStyle: {
      borderBottomColor: "#C1C7D0",
      borderBottomWidth: 1,
    },
    columnBodyWrapper: {
      flex: 1,
      paddingLeft: isDesktopOrLarger ? 8 : 0,
      paddingVertical: 10,
    },
    columnText: {
      fontSize: isDesktopOrLarger ? 14 : 12,
      fontWeight: "400",
    },
    tableBodyWrapper: {
      flexDirection: "row",
      marginTop: 5,
    },
  });

  const buttonText = claimsToShow.length < claims.length ? "See more history" : "Collapse history";

  const fullName = `${firstName} ${lastName}`;

  const getStatusTitle = (status: Status) => {
    switch (status) {
      case "pending":
        return "Pending";
      case "accepted":
        return "Disbursed";
      case "rejected":
        return "Rejected";
      case "deferred":
        return "Deferred";
      case "duplicate":
        return "Duplicate";
      default:
        throw new UnreachableCaseError(status);
    }
  };

  const claimItems = (
    <View>
      {claimsToShow.map(({ amount, createdAt, id: claimId, status }) => (
        <View key={claimId} style={claimStyles.tableBodyWrapper}>
          <View style={[claimStyles.columnBodyWrapper, claimStyles.borderStyle]}>
            <Text
              style={claimStyles.columnText}
              {...setTestProps({ name: `${claimId}-SubmittedDate-ChildCard` })}
            >
              {dayjs(createdAt).format("MM/DD/YYYY")}
            </Text>
            {!isDesktopOrLarger && (
              <View style={claimStyles.columnBodyWrapper}>
                <Text
                  style={[claimStyles.columnText, { fontSize: 24 }]}
                  {...setTestProps({ name: `${claimId}-ClaimAmount-ChildCard` })}
                >
                  {formatCurrency(amount)}
                </Text>
              </View>
            )}
          </View>
          {isDesktopOrLarger && (
            <View style={[claimStyles.columnBodyWrapper, claimStyles.borderStyle]}>
              <Text
                style={claimStyles.columnText}
                {...setTestProps({ name: `${claimId}-ClaimAmount-ChildCard` })}
              >
                {formatCurrency(amount)}
              </Text>
            </View>
          )}
          <View style={[claimStyles.columnBodyWrapper, claimStyles.borderStyle]}>
            <Text
              style={[claimStyles.columnText, { textAlign: isDesktopOrLarger ? "left" : "right" }]}
              {...setTestProps({ name: `${claimId}-Status-ChildCard` })}
            >
              {getStatusTitle(status)}
            </Text>
          </View>
        </View>
      ))}
    </View>
  );

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <View>
          <Text
            numberOfLines={1}
            style={styles.headerText}
            {...setTestProps({ name: `${fullName}-ChildCard` })}
          >
            {fullName}
          </Text>
        </View>
      </View>
      <View>
        <View style={styles.boxWrapper}>
          {isDesktopOrLarger ? (
            <View style={{ flexDirection: "row" }}>
              <AmountCard
                amount={dollars.available}
                childName={fullName}
                title="Total Credit Amount"
              />
              <AmountCard
                amount={dollars.pending}
                childName={fullName}
                title="Credit Amount Remaining"
              />
              <AmountCard
                amount={dollars.spent}
                childName={fullName}
                title="Credit Amount Disbursed"
              />
            </View>
          ) : (
            <AmountCard
              amount={dollars.available}
              childName={fullName}
              title="Total Credit Amount"
            />
          )}
          {!isDesktopOrLarger && (
            <View style={{ flexDirection: "row" }}>
              <AmountCard
                amount={dollars.pending}
                childName={fullName}
                title="Credit Amount Remaining"
              />
              <AmountCard
                amount={dollars.spent}
                childName={fullName}
                title="Credit Amount Disbursed"
              />
            </View>
          )}
        </View>
        <View style={{ paddingHorizontal: isDesktopOrLarger ? 8 : 16 }}>
          {isDesktopOrLarger && claims.length > 0 && (
            <View style={styles.tableHeadingWrapper}>
              <View style={styles.columnHeadingWrapper}>
                <Text style={styles.columnHeading}>Date</Text>
              </View>
              <View style={styles.columnHeadingWrapper}>
                <Text style={styles.columnHeading}>Credit Installment</Text>
              </View>
              <View style={styles.columnHeadingWrapper}>
                <Text style={styles.columnHeading}>Status</Text>
              </View>
            </View>
          )}
          {Boolean(claims.length) && Platform.OS === "web" && (
            <View style={styles.horizontalLine} />
          )}
          {claimItems}
          {claims.length > NUMBER_OF_MINIMUM_CLAIMS_TO_DISPLAY && (
            <View style={styles.buttonWrapper}>
              <Pressable
                onPress={() => {
                  setShowAllClaims(previousValue => !previousValue);
                }}
              >
                <Text
                  style={{ color: "#006699" }}
                  {...setTestProps({ name: `${buttonText}-Link-ChildCard` })}
                >
                  {buttonText}
                </Text>
              </Pressable>
            </View>
          )}
        </View>
      </View>
    </View>
  );
};
