import { config } from "../config/config";
import axios from "axios";

type GetAgentLinksResponse = {
  readonly capabilitiesApproved: boolean;
  readonly tosAccepted: boolean;
};

export const AgentClient = (accessToken: string) => {
  const { baseUrl: baseURL } = config.api.agent;
  const httpClient = axios.create({
    baseURL,
    headers: { Authorization: `Bearer ${accessToken}` },
  });

  const getLinks = async (agentID: string) => {
    const url = `${baseURL}/v1/agents/${agentID}/links`;
    const { data } = await httpClient.get<GetAgentLinksResponse>(url);

    return data;
  };

  const acceptToS = async (agentID: string) => {
    const url = `${baseURL}/v1/agents/${agentID}/accept-tos`;
    await httpClient.post(url);
  };

  const linkEntity = async (agentID: string, entityID: string) => {
    const url = `${baseURL}/v1/agents/${agentID}/link/${entityID}`;
    await httpClient.put(url);
  };

  return {
    acceptToS,
    getLinks,
    linkEntity,
  };
};
