import { Linking, Pressable, Text } from "react-native";
import { Some } from "../utils/Some";
import { setTestProps } from "../utils/propHelper";
import React from "react";
import type { StyleProp, TextStyle } from "react-native";

type Props = {
  readonly url: string;
  readonly text: string;
  readonly onPress?: () => void;
  readonly textStyle?: StyleProp<TextStyle>;
  readonly testID?: string;
};
export const ExternalLink = ({ onPress, testID, text, textStyle, url }: Props) => (
  <Pressable
    onPress={() => {
      Linking.openURL(url);

      if (Some(onPress)) {
        onPress();
      }
    }}
  >
    <Text style={[{ color: "#006699" }, textStyle]} {...setTestProps({ name: testID })}>
      {text}
    </Text>
  </Pressable>
);
