// Copyright 2022 Merit International Inc. All Rights Reserved.

import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import AsyncStorage from "@react-native-async-storage/async-storage";
import type { PersistOptions } from "zustand/middleware";
import type { StateCreator } from "zustand";

export type TokenState = {
  readonly token: string | undefined;
  readonly refreshToken: (token: string) => void;
};

// HACK: workaround for https://github.com/pmndrs/zustand/issues/650 in recent zustand versions
type TypedPersist = (
  config: StateCreator<TokenState>,
  options: PersistOptions<TokenState>,
) => StateCreator<TokenState>;

export const useTokenStore = create<TokenState>(
  (persist as unknown as TypedPersist)(
    set => ({
      refreshToken: (token: string) => {
        set({ token });
      },
      token: undefined,
    }),
    {
      name: "tokenstore-storage",
      storage: createJSONStorage(() => AsyncStorage),
    },
  ),
);
