import { Button, Platform, Text } from "react-native";
import { CenteredContent } from "../../Layouts/CenteredContent";
import { Some } from "../../utils/Some";
import { reloadAsync } from "expo-updates";
import { setTestProps } from "../../utils/propHelper";
import { useLogout } from "../../hooks/auth";
import { useUserStore } from "../../store/userStore";
import React from "react";

type Props = {
  readonly description?: string;
  readonly heading: string;
};

export const ErrorScreen = ({ description, heading }: Props) => {
  const logoutFromAuth0 = useLogout();
  const logoutFromStore = useUserStore(_ => _.logout);

  const startAgain = () => {
    try {
      logoutFromStore();
      logoutFromAuth0();
      // Just swallow errors, in case the problem is somehow to do with localStorage etc..
    } catch {}

    if (Platform.OS === "web") {
      window.location.replace("/");
    } else {
      reloadAsync();
    }
  };

  return (
    <CenteredContent>
      <Text style={{ marginBottom: 40 }} {...setTestProps({ name: "header-ErrorScreen" })}>
        {heading}
      </Text>

      {Some(description) && <Text style={{ marginBottom: 40 }}>{description}</Text>}

      {Platform.OS === "web" ? (
        <a data-testid="startAgainLink-ErrorScreen" href="/" onClick={startAgain}>
          Start again from homepage
        </a>
      ) : (
        <Button
          onPress={startAgain}
          title="Start again"
          {...setTestProps({ name: "startAgainButton-ErrorScreen" })}
        />
      )}
    </CenteredContent>
  );
};
