import { Image, Platform, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { useDeviceSize } from "../../utils/useDeviceSize";
import React, { useState } from "react";
import ShowMoreInfoTooltipIcon from "../../../assets/icons/information_outlined_m_default.png";

type TooltipProps = {
  readonly text: string;
};

export const Tooltip = ({ text }: TooltipProps) => {
  const [showMoreInfoTooltip, setShowMoreInfoTooltip] = useState(false);
  const { isDesktopOrLarger } = useDeviceSize();

  const styles = StyleSheet.create({
    tooltip: {
      backgroundColor: "#007A98",
      borderRadius: 4,
      ...(Platform.OS === "web" && { boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)" }),
      paddingHorizontal: 16,
      paddingVertical: 12,
      position: "absolute",
      right: 10,
      shadowColor: "rgba(0, 0, 0, 0.1)",
      shadowOpacity: 0.8,
      shadowRadius: 4,
      width: 100,
      zIndex: 99,
    },
  });

  return (
    <View>
      {showMoreInfoTooltip && (
        <View style={[styles.tooltip, { right: isDesktopOrLarger ? 0 : 10, top: 40 }]}>
          <Text style={{ color: "#FFFFFF" }}>{text}</Text>
        </View>
      )}
      <TouchableOpacity
        onPress={() => {
          setShowMoreInfoTooltip(prevState => !prevState);
        }}
      >
        <Image accessible source={ShowMoreInfoTooltipIcon} style={{ height: 20, width: 20 }} />
      </TouchableOpacity>
    </View>
  );
};
