import { Login } from "../__generated__/Login";
import { config } from "../config/config";

export const LoginClient = (accessToken: string) => {
  const loginClient = new Login({
    baseURL: config.api.baseUrl,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return loginClient;
};
