/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

// merit/form214: Import custom error for 400 status code
// merit/form214

import { HttpClient, RequestParams } from "./http-client";

// merit/form214: Possible response structure for generated api clients
type Response<T = void> =
  | {
      readonly success: false;
      readonly message: string;
    }
  | (T extends void
      ? {
          readonly success: true;
        }
      : {
          readonly success: true;
          readonly data: T;
        });
// merit/form214

export class User<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name GetUser
   * @request GET:/api/user/
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  getUser = (params: RequestParams = {}) =>
    this.request<
      {
        children: {
          claims: {
            amount: number;
            createdAt: string;
            id: number;
            status:
              | "pending"
              | "accepted"
              | "rejected"
              | "deferred"
              | "duplicate"
              | ("pending" & "accepted" & "rejected" & "deferred" & "duplicate");
          }[];
          dollars: {
            available: number;
            pending: number;
            spent: number;
          };
          firstName: string;
          id: number;
          lastName: string;
        }[];
        email: string;
        firstName: string;
        id: number;
        lastName: string;
        type: "Parent";
      },
      any
    >({
      path: `/api/user/`,
      method: "GET",
      format: "json",
      ...params,
    });

  /**
   * No description
   *
   * @name GetUserByParent
   * @request GET:/api/user/parent
   */

  // merit/form214: Api client body when route handler has a possible 400 staus code
  // merit/form214

  // merit/form214: Api client body when route handler do not have a possible 400 staus code
  getUserByParent = (params: RequestParams = {}) =>
    this.request<
      {
        children: {
          claims: {
            amount: number;
            createdAt: string;
            id: number;
            status:
              | "pending"
              | "accepted"
              | "rejected"
              | "deferred"
              | "duplicate"
              | ("pending" & "accepted" & "rejected" & "deferred" & "duplicate");
          }[];
          dollars: {
            available: number;
            pending: number;
            spent: number;
          };
          firstName: string;
          id: number;
          lastName: string;
        }[];
        email: string;
        firstName: string;
        id: number;
        lastName: string;
        type: "Parent";
      },
      any
    >({
      path: `/api/user/parent`,
      method: "GET",
      format: "json",
      ...params,
    });
}
