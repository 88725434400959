// Copyright 2023 Merit International Inc. All Rights Reserved

import { config } from "./config";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";

datadogRum.init({
  applicationId: config.datadog.web.applicationId,
  clientToken: config.datadog.web.clientToken,
  defaultPrivacyLevel: "mask-user-input",
  env: config.datadog.environment,
  service: "ACE-OHIO Web",
  sessionReplaySampleRate: 100,
  sessionSampleRate: 100,
  site: "datadoghq.com",
});

datadogLogs.init({
  clientToken: config.datadog.web.clientToken,
  env: config.datadog.environment,
  forwardConsoleLogs: ["warn", "error", "info"],
  forwardErrorsToLogs: true,
  service: "ACE-OHIO Web",
  sessionSampleRate: 100,
  site: "datadoghq.com",
});
