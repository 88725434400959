import { Auth0Provider } from "@auth0/auth0-react";
import { config } from "../../config/config";
import React from "react";
import type { FCWithChildren } from "../../types/component";

const AuthProvider: FCWithChildren = ({ children }) => {
  const { auth0Domain, clientId } = config.auth;

  return (
    <Auth0Provider
      authorizationParams={{ audience: config.api.issuance.baseUrl }}
      cacheLocation="localstorage"
      clientId={clientId}
      domain={`https://${auth0Domain}`}
    >
      {children}
    </Auth0Provider>
  );
};

export { AuthProvider };
