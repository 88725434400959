// Copyright 2022 Merit International Inc. All Rights Reserved

import { Button } from "../components/Button";
import { ChildCard } from "../components/ChildCard";
import {
  Image,
  Linking,
  Platform,
  Pressable,
  SafeAreaView,
  ScrollView,
  StatusBar,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { None } from "../utils/None";
import { setTestProps } from "../utils/propHelper";
import { useDeviceSize } from "../utils/useDeviceSize";
import { useLogout } from "../hooks/auth";
import { useUserStore } from "../store/userStore";
import Help from "../../assets/icons/help.png";
import React from "react";

export const Dashboard = () => {
  const parent = useUserStore(_ => _.parent);

  if (None(parent)) {
    throw new Error("Logged in user is not parent");
  }

  const logoutFromAuth0 = useLogout();
  const logoutFromStore = useUserStore(_ => _.logout);
  const { isDesktopOrLarger } = useDeviceSize();
  const helpCenterLink =
    "https://help.parentalchoice.ok.gov/article/324-about-the-disbursement-process";

  const styles = StyleSheet.create({
    bottomHelpLink: {
      fontFamily: "Proxima Nova",
      fontSize: 14,
      fontWeight: "400",
      lineHeight: 20,
      paddingVertical: 30,
      textAlign: "center",
    },
    container: {
      alignItems: "center",
      backgroundColor: "#FFFFFF",
      flex: 1,
      justifyContent: "space-between",
      paddingVertical: 24,
    },
    header: {
      alignItems: "center",
      backgroundColor: "#3D7AA9",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingHorizontal: 24,
      paddingVertical: 24,
    },
    headerText: {
      color: "#FFFFFF",
      fontSize: 20,
      fontWeight: "600",
    },
    link: {
      color: "#000000",
      fontSize: 14,
      paddingVertical: 8,
      textAlign: "center",
      textDecorationLine: "none",
    },
    linkText: {
      color: "#006699",
    },
    linkWrapper: {
      backgroundColor: "#6CADDF",
      border: "solid 1px #3D7AA9",
      borderRadius: 4,
      marginBottom: 8,
      opacity: 0.9,
      width: Platform.OS === "web" ? 200 : "80%",
    },
    scrollViewContent: {
      flex: 1,
      paddingHorizontal: 24,
      width: isDesktopOrLarger ? "40%" : "100%",
    },
  });

  const openLink = () => {
    Linking.openURL(helpCenterLink);
  };

  return (
    <SafeAreaView style={{ backgroundColor: "#fff", flex: 1 }}>
      <StatusBar backgroundColor="#3D7AA9" />
      <View style={styles.header}>
        <Text style={styles.headerText} {...setTestProps({ name: "title-Dashboard" })}>
          Oklahoma ESA
        </Text>
        <View style={{ flexDirection: "row" }}>
          <Pressable
            onPress={() => {
              Linking.openURL("https://help.parentalchoice.ok.gov");
            }}
            style={{ flexDirection: "row", marginRight: 20, paddingVertical: 6 }}
          >
            <Image
              accessible
              source={Help}
              style={{ height: 20, width: 20 }}
              {...setTestProps({ name: "helpIcon-Dashboard" })}
            />
            <View style={{ justifyContent: "center", marginLeft: 8 }}>
              <Text style={{ color: "#FFFFFF" }} {...setTestProps({ name: "helpLink-Dashboard" })}>
                {isDesktopOrLarger ? "Help center" : "Help"}
              </Text>
            </View>
          </Pressable>
          <Button
            onPress={() => {
              logoutFromStore();
              logoutFromAuth0();
            }}
            size="small"
            testID="logoutButton-Dashboard"
            text="Logout"
            type="secondary"
          />
        </View>
      </View>
      <View style={styles.container}>
        <ScrollView showsVerticalScrollIndicator={false} style={{ width: "100%" }}>
          <View style={{ alignItems: "center" }}>
            <View style={styles.scrollViewContent}>
              {parent.children.map(child => (
                <View key={child.firstName} style={{ paddingVertical: 12 }}>
                  <ChildCard {...child} />
                </View>
              ))}
              {/* Footer */}
              <View style={styles.bottomHelpLink}>
                <Text>
                  To learn more about the disbursement process, visit the{" "}
                  <TouchableOpacity onPress={openLink}>
                    <Text style={styles.linkText}>PCTC Help Center1</Text>
                  </TouchableOpacity>
                </Text>
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    </SafeAreaView>
  );
};
